.privacy

.privacy-h2 {
   font-family: "IBM Plex Sans", sans-serif;
   font-weight: 600px;
   color: rgb(33, 37, 41);
   font-size: 32px;
   line-height: 45px;
}

.privacy-p {
   font-family: "IBM Plex Sans", sans-serif;
   font-weight: 400px;
   color: rgb(33, 37, 41);
   font-size: 16px;
   line-height: 26px;
}