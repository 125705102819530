.resume-box  { 
    width: 100%;
    height: 90px;
    position: relative; 
    cursor: pointer;
    /* background: #fff; */
    background-color: rgb(10, 88, 202);
    background-image: initial; 
    /* border-style: dotted !important; */
    display: flex; 
    flex-direction: column; 
    align-items: center;
    border-radius: 12px; 
    /* border: 2px dotted #202324; */
    padding: 10px;
    overflow: hidden;
    
    /* Media query for making resume-box responsive */
    @media only screen and (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
      }
} 

/* .resume-box:hover {
    scale: 1.09;
} */

.drag-drop-text {
  color: #ffffff;
}
.resume-box .MuiLinearProgress-root {
    
    width: calc(100% - 20px);
    margin: 0 10px;
    position: absolute;
    bottom: 6px; 
    left: 0;
    z-index: 2;
   
    
  }

.resume-box .MuiTypography-root {
    position: absolute;
    bottom: 16px;
    right: 14px;
    z-index: 3;
}

.resume-box:hover  { 
    box-shadow: rgba(0, 0, 0, 0.15) 0px 18px 34px 0px;
} 

.fa-2x {
    font-size: 1.7em;
}


/* :selection { 
    background-color: #004daa !important; 
    color: #e8e6e3 !important;
}  */

:-webkit-scrollbar { 
    background-color: #202324; 
    color: #aba499;
} 

:-webkit-scrollbar-thumb { 
    background-color: #454a4d;
} 

:-webkit-scrollbar-thumb:hover { 
    background-color: #575e62;
} 

:-webkit-scrollbar-corner { 
    background-color: #181a1b;
} 





