
.bg-cta{
  
    background: url('../images/cta-bg.jpg');
    margin-top: 50px;

}
.bg-overlay1{
    background-color: rgba(110, 110, 153, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}
.offcanvas-body1{
    width: 450px !important;
}
.bg-overlay-white-color{
    background-color: rgb(240 248 255 / 94%);;
}
.bg-overlay-white-color-contact{
    background-color: rgb(240 248 255 / 80%);;
}