/* Template Name: Landrick - App & Saas Landing Page Template
   Author: Themesbrand
   File Description: Main Css file of the template
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");

/* ====================================Pravesh========================= */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,300);


.frame {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	height: 400px;
	margin-top: -200px;
	margin-left: -200px;
	border-radius: 2px;
	box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;
	background: linear-gradient(to top right, darkmagenta 0%, hotpink 100%);
	color: #333;
	font-family: "Open Sans", Helvetica, sans-serif;
}

.iframe-video {
  @media (max-width: 767px) {
    height: auto; 
    max-width: 100%,
}


.score-btn-container {
  @media (max-width: 500px) {
    
    display: flex;
    flex-direction: column;
    align-items: center;
      
  }
}

}

.centers {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 300px;
	height: 260px;
	border-radius: 3px;
	box-shadow: 8px 10px 15px 0 rgba(0, 0, 0, 0.2);
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
}

.titles {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #999;
	text-align: center;
}

.h1h1 {
	font-size: 16px;
	font-weight: 300;
	color: #666;
}


.icon-line {
  
  /* Add the following styles for extra small devices */
  /* @media only screen and (max-width: 400px) {
    margin-right: 50px;
    .icon {
      margin-right: 10px;
      size: sm;
    }
  } */
  
  /* Add styles for small devices 
  @media only screen and (min-width: 401px) and (max-width: 600px) {
    .icon {
      margin-right: 10px;
      size: 1.5x
    }
     margin-right: 45px;
  } */
   
  
}



/* .dropzone {
  width: 65%;
  background: #0a58ca;
  border-radius: 4px;
  padding: 20px;
  color:white;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  
} */

.dropzone {
  width: 65%;
  background: #0a58ca;
  border-radius: 4px;
  padding: 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.dropzone.active {
  border: 2px dashed #fff; /* Add a dashed border when active */
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Adjust the appearance of the progress bar */
.progress-bar-fill {
  height: 100%;
  background: #ffca28;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.upload-icon {
	margin: 25px 2px 2px 2px;
}

.upload-input {
	position: relative;
	top: -62px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.btns {
	display: block;
	width: 140px;
	height: 40px;
	background: darkmagenta;
	color: #fff;
	border-radius: 3px;
	border: 0;
	box-shadow: 0 3px 0 0 hotpink;
	transition: all 0.3s ease-in-out;
	font-size: 14px;
}

.btns:hover {
	background: rebeccapurple;
	box-shadow: 0 3px 0 0 deeppink;
}



/* ===================================Pravesh===================== */
.bg-primary {
  background-color: #2f55d4 !important;
}

.text-primary {
  color: #2f55d4 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #284bc1 !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #506180 !important;
}

.bg-success {
  background-color: #2eca8b !important;
}

.text-success {
  color: #2eca8b !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #29b57d !important;
}

.bg-warning {
  background-color: #f17425 !important;
}

.text-warning {
  color: #f17425 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ed650f !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #148ea1 !important;
}

.bg-danger {
  background-color: #e43f52 !important;
}

.text-danger {
  color: #e43f52 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e1293e !important;
}

.bg-dark {
  background-color: #3c4858 !important;
}

.text-dark {
  color: #3c4858 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #323c49 !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.text-muted {
  color: #8492a6 !important;
}

a.text-muted:hover,
a.text-muted:focus {
  color: #75859b !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

.text-light {
  color: #f8f9fc !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #e6eaf4 !important;
}

.bg-footer {
  background-color: #202942 !important;
}

.text-footer {
  color: #202942 !important;
}

a.text-footer:hover,
a.text-footer:focus {
  color: #181e30 !important;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.border {
  border: 1px solid #e9ecef;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.small,
small {
  font-size: 90%;
}

.card .card-body {
  padding: 1.5rem;
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
  font-size: 16px;
  color: #161c2d;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito", sans-serif;
}

::-moz-selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

::selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

.btn-primary {
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
  color: #ffffff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
  background-color: #2443ac !important;
  border-color: #2443ac !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid #2f55d4;
  color: #2f55d4;
  background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-success {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #2eca8b;
  color: #2eca8b;
  background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-warning {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #f17425;
  color: #f17425;
  background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-info {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-danger {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(2, 2, 2, 0.3);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #e43f52;
  color: #e43f52;
  background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-dark {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn:focus {
  box-shadow: none !important;
}

button:not(:disabled) {
  outline: none;
  border: 0;
}

.shadow {
  box-shadow: 0 0 3px rgba(22, 28, 45, 0.15) !important;
}

.shadow-large {
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.badge-primary {
  background-color: #2f55d4 !important;
  color: #ffffff !important;
}

.badge-outline-primary {
  background-color: transparent !important;
  color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
}

.badge-secondary {
  background-color: #5a6d90 !important;
  color: #ffffff !important;
}

.badge-outline-secondary {
  background-color: transparent !important;
  color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
}

.badge-success {
  background-color: #2eca8b !important;
  color: #ffffff !important;
}

.badge-outline-success {
  background-color: transparent !important;
  color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
}

.badge-warning {
  background-color: #f17425 !important;
  color: #ffffff !important;
}

.badge-outline-warning {
  background-color: transparent !important;
  color: #f17425 !important;
  border: 1px solid #f17425 !important;
}

.badge-info {
  background-color: #17a2b8 !important;
  color: #ffffff !important;
}

.badge-outline-info {
  background-color: transparent !important;
  color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
}

.badge-danger {
  background-color: #e43f52 !important;
  color: #ffffff !important;
}

.badge-outline-danger {
  background-color: transparent !important;
  color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
}

.badge-dark {
  background-color: #3c4858 !important;
  color: #ffffff !important;
}

.badge-outline-dark {
  background-color: transparent !important;
  color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
}

.badge-muted {
  background-color: #8492a6 !important;
  color: #ffffff !important;
}

.badge-outline-muted {
  background-color: transparent !important;
  color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
}

.badge-light {
  background-color: #f8f9fc !important;
  color: #ffffff !important;
}

.badge-outline-light {
  background-color: transparent !important;
  color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
}

.badge-footer {
  background-color: #202942 !important;
  color: #ffffff !important;
}

.badge-outline-footer {
  background-color: transparent !important;
  color: #202942 !important;
  border: 1px solid #202942 !important;
}

.badge.badge-light {
  color: #3c4858 !important;
  background-color: #f8f9fc !important;
}

.badge.badge-outline-light {
  color: #3c4858 !important;
  border: 1px solid #b1bcdd !important;
  background-color: transparent !important;
}

.badge.badge-pill {
  border-radius: 20px;
}

.badge:focus,
.badge.focus {
  box-shadow: none !important;
}

.fea {
  stroke-width: 1.8;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

.avatar.avatar-sm {
  height: 50px;
  width: 50px;
}

/* .avatar.avatar-small {
  height: 85px;
  width: 85px;
} */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #2f55d4;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.back-to-top .icons {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  stroke-width: 2;
  height: 14px;
  width: 14px;
}

.back-to-top:hover {
  background-color: #2f55d4;
  color: #ffffff;
  transform: rotate(45deg);
}

.back-to-top:hover .icons {
  transform: rotate(-45deg);
}

.bg-half-260 {
  padding: 260px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-170 {
  padding: 170px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
.demos-box img {
  transition: all 0.5s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

.demos-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.demos-box .title {
  font-weight: 600;
  letter-spacing: 1px !important;
  font-size: 18px;
}
.demos-box {
  position: relative !important;
  z-index: 1 !important;
  transition: all 0.5s ease !important;
}

Feature .key-feature {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.key-feature .content .title {
  font-size: 18px;
}

.key-feature .icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 24px;
  background: linear-gradient(90deg, transparent, rgba(47, 85, 212, 0.15));
}

.key-feature:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .attach-icons-hero {
    position: absolute;
    right: 0;
    top: -35px;
    left: 0;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .bg-half-260 {
    padding: 150px 0;
    height: auto;
  }

  .attach-icons-hero {
    border-bottom: 1px solid #e9ecef;
  }
}

.section {
  padding: 100px 0;
  position: relative;
}

.title-heading {
  line-height: 26px;
}

.title-heading .heading {
  font-size: 40px;
  letter-spacing: 1px;
}

.section-title {
  position: relative;
  margin-top: 10px;
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mover {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
  .title-heading .heading {
    font-size: 30px !important;
  }
  .title-heading .para-desc {
    font-size: 15px;
  }
  .mt-60 {
    margin-top: 60px;
  }
}

.footer {
  background: #202942;
  position: relative;
  color: #adb5bd;
}

.footer.footer-bar {
  padding: 30px 0;
  border-top: 1px solid #3d4349;
}
.Featuredbackground {
  color: #000000;
}
.herobackgroungimg {
  width: 100%;
  background-image: url("../../../assets/images/shapes/herobackimg.png");
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Featuredjobsbg{
  width: 100%;
  background-image: url("../../../assets/images/shapes/shape2.png");
  /* height: 600px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
